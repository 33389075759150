import React, { ReactElement, useState } from 'react';

import './survey-page.scss';
import SurveyForm from './survey-form/survey-form';
import { Question } from '../../models/question';
import PrivacyForm from './privacy-form/privacy-form';
import ResultForm from './result-form/result-form';
import SurveyHeader from '../../components/survey-header/survey-header';
import { CreatingAnswer, Score } from '../../models/answer';
import AnswerService from '../../services/answer-service';
import { useLocation, useNavigate } from 'react-router-dom';
import KakaoTalkService from '../../services/kakaotalk-service';


export default function SurveyPage(): ReactElement {
    const location = useLocation();
    const navigate = useNavigate();
    const [ step, setStep ] = useState<number>(0);
    const [ answer, setAnswer ] = useState<CreatingAnswer>({
        name: undefined,
        phoneNumber: undefined,
        birthDay: new Date(),
        createdDate: new Date(),
        choices: [],
        scores: [],
        resultType: undefined
    } as CreatingAnswer);

    const queryParams = new URLSearchParams(location.search);
    const from = queryParams.get('from');

    function renderItem(items: any[]): ReactElement {
        return items[step];
    }

    function onPrivacyInfoEntered(name: string, telNumber: string, birthDay: Date) {
        setAnswer({
            ...answer,
            name,
            phoneNumber: telNumber,
            birthDay
        });
        setStep(step + 1);
    }

    function onQuestionAnswered(questionIndex: number, answerIndex: number) {
        const a = {
            ...answer
        };
        a.choices[questionIndex] = answerIndex;
        setAnswer(a);
        setStep(step + 1)
    }

    function onSubmitted() {
        const scoreMapping = [ 4, 3, 2, 1 ];
        const t = answer.choices.map(c => scoreMapping[c]);
        const scores: Score[] = [
            { type: 'A', score: t[0] + t[1] },
            { type: 'B', score: t[2] + t[3] },
            { type: 'C', score: t[4] + t[5] },
            { type: 'D', score: t[6] + t[7] },
            { type: 'E', score: t[8] + t[9] }
        ];

        const sortedScores: Score[] = scores.sort((a: Score, b: Score): number => {
            const r = b.score - a.score;
            if (r !== 0) {
                return r;
            }

            return a.type.charCodeAt(0) - b.type.charCodeAt(0);
        });

        const result: CreatingAnswer = {
            ...answer,
            scores: sortedScores,
            resultType: sortedScores[0].type
        };

        const service = new AnswerService();
        service.write(result).then(() => {
            navigate(`/result/${result.resultType}` + (from ? `?from=${from}` : ''));
        });

        const kakaoTalkService = new KakaoTalkService();
        kakaoTalkService.sendMessage(answer.phoneNumber!, answer.name!, sortedScores[0].type).then(a => {
        });
    }

    const questions: Question[] = [
        {
            title: 'Q1',
            description: '<p>어떤 자극에 피부가 붉게 반응하나요?</p>',
            answers: [
                '환경 변화나 미세한 자극에도 쉽게 붉어지며 오래 지속된다.',
                '사소한 자극에도 쉽게 붉어지지만 금방 원래대로 돌아온다.',
                '긁는 행위와 같이 강한 자극이 가해졌을 때에만 붉어진다.',
                '피부가 붉어지는 현상이 거의 없다.'
            ]
        },
        {
            title: 'Q2',
            description: '<p>피부의 질감은 어떤가요?</p>',
            answers: [
                '눈으로 보았을 때 확연히 피부가 거칠어 보인다.',
                '만져보았을 때만 거칠게 느껴진다.',
                '눈으로 볼 때나 만져볼 때나 거칠어 보이지 않는다.',
                '부드럽고 매끄럽다.'
            ]
        },
        {
            title: 'Q3',
            description: '<p>세안 직후 피부 상태는 어떤가요?</p>',
            answers: [
                '전체적으로 꽉 조이듯이 당긴다.',
                '볼과 이마 같은 넓은 부위가 미세하게 당긴다.',
                '겉으로 보기에는 괜찮으나 피부 속에 당김이 느껴진다.',
                '전체적으로 당기는 느낌이 전혀 없다.',
            ]
        },
        {
            title: 'Q4',
            description: '<p>세안 후 기초 제품들을 바른 피부의 상태는 어떤가요?</p>',
            answers: [
                '바른 직후에도 건조감이 유지된다.',
                '괜찮은 듯 하나 금방 수분감을 잃고 건조감이 느껴진다.',
                '2~3시간 가량 보습감이 지속된다.',
                '하루 종일 건조감 없이 부드럽고 촉촉하게 유지된다.',
            ]
        },
        {
            title: 'Q5',
            description: '<p>찡그리거나 웃는 표정을 지을때<br/>얼굴 피부의 주름 상태는 어떤가요?</p>',
            answers: [
                '얼굴 전체적으로 주름이 존재한다.',
                '움직임이 많은 눈가와 입가 근처에만 주름이 존재한다.',
                '부분적으로 주름이 옅게 눈에 띈다.',
                '주름이 거의 보이지 않는다.',
            ]
        },
        {
            title: 'Q6',
            description: '<p>표정을 풀었을때 얼굴 피부의 주름 상태는 어떤가요?</p>',
            answers: [
                '전체적으로 주름의 흔적이 유지된다.',
                '움직임이 많은 눈가와 입가 근처에만 주름의 흔적이 유지된다.',
                '아주 흐린 주름의 흔적이 잠시 남았다가 금방 사라진다.',
                '흔적없이 매끈하다.',
            ]
        },
        {
            title: 'Q7',
            description: '<p>일상적인 피부 상태는 어떤가요?</p>',
            answers: [
                '피부 속은 당기나 겉은 번들거림이 심하다.',
                '건조감이 느껴지나 피부 표면은 기름기가 느껴진다.',
                '미세하게 건조감이 느껴진다.',
                '건조감과 유분기가 모두 느껴지지 않는다.',
            ]
        },
        {
            title: 'Q8',
            description: '<p>평균적으로 트러블이 생기는 주기가 어떻게 되나요?</p>',
            answers: [
                '며칠에 한번씩 빈번히 생긴다.',
                '2~3주에 한번씩 생긴다.',
                '몇 달 혹은 몇 년에 한번씩 생긴다.',
                '거의 생기지 않는다.',
            ]
        },
        {
            title: 'Q9',
            description: '<p>얼굴에 기미나 주근깨 같은 잡티가<br/>얼마나 많이 생기나요?</p>',
            answers: [
                '전체적으로 많이 분포되어 있고 계속해서 생긴다.',
                '오래 유지되진 않지만 주기적으로 새로운 잡티가 생긴다.',
                '몇 년에 한번씩 자잘한 잡티가 생긴다.',
                '눈에 띄는 잡티가 없고 거의 생기지 않는다.',
            ]
        },
        {
            title: 'Q10',
            description: '<p>피부 트러블이나 상처가 아문 후<br/>갈색 자국이 얼마나 지속되나요?</p>',
            answers: [
                '6개월 ~ 1년 이상',
                '1~6개월 사이',
                '4주 이내',
                '생기지 않는다.',
            ]
        },
    ];

    const items = [
        <PrivacyForm
            name={answer.name}
            phoneNumber={answer.phoneNumber}
            birthDay={answer.birthDay}
            onEntered={onPrivacyInfoEntered}/>,
        ...(questions.map((q, qi) =>
            <SurveyForm question={q}
                        selected={answer.choices[qi]}
                        onAnswered={(ai) => onQuestionAnswered(qi, ai)}
                        onCanceled={() => setStep(step - 1)}
                        maxStepCount={questions.length + 1}
                        currentStep={qi + 1}/>
        )),
        <ResultForm name={answer.name!} onSubmitted={onSubmitted}/>
    ]

    return (
        <div className={'survey-page'}>
            <SurveyHeader closable={true} onClosed={() => {
                navigate(`/${from}`);
            }}/>
            <div className={'survey-page-content'}>{renderItem(items)}</div>
        </div>
    );
}

import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import './result-form.scss'
import { Button } from '@mui/material';
import React from 'react';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 7,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#FFFFFFAD',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 7,
        backgroundColor: '#E1D8CA;',
    },
}));

export interface ResultFormProps {
    name: string;
    onSubmitted: () => void;
}

export default function ResultForm(props: ResultFormProps) {
    return (
        <div className={'result-form'}>
            <div className={'result-form-title'}>Skin Report</div>
            <div className={'result-form-progress'}>
                <BorderLinearProgress variant="determinate" value={100.0}/>
            </div>
            <div className={'result-form-description'}>스킨 리포트 작성을 완료했어요.</div>

            <div className={'result-form-content'}>
                <p>스킨 리포트를 제출하고</p>
                <p>{props.name} 님의 피부 타입에 대한 컨설테이션을 확인하세요.</p>
                <p><br/></p>
                <p>시르즈의 스킨 컨설턴트가 {props.name} 님의 피부를 분석해</p>
                <p>진정성 가득한 신시어 키트를 제공해드립니다.</p>

                <div className={'result-form-content-notice'}>세심한 케어로 개선되는 피부를 경험하세요.</div>
            </div>

            <div className={'result-form-action-bar'}>
                <div className={'space'}></div>
                <Button className={'right'} onClick={props.onSubmitted}>제출하기</Button>
            </div>
        </div>
    );
}

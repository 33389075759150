import { ReactElement, useEffect, useState } from 'react';
import TextInputField from '../../../components/text-input-field/text-input-field';
import BirthDaySelector from '../../../components/birth-day-selector/birth-day-selector';

import './privacy-form.scss';
import { Button } from '@mui/material';
import KakaoTalkService from '../../../services/kakaotalk-service';

interface PrivacyFormProps {
    name?: string;
    phoneNumber?: string;
    birthDay?: Date;
    onEntered: (name: string, telNumber: string, birthDay: Date) => void;
}

export default function PrivacyForm(props: PrivacyFormProps): ReactElement {
    const [ disabled, setDisabled ] = useState<boolean>(false);
    const [ name, setName ] = useState<string | undefined>(props.name);
    const [ phoneNumber, setPhoneNumber ] = useState<string | undefined>(props.phoneNumber);
    const [ birthDay, setBirthDay ] = useState<Date | undefined>(props.birthDay);

    // function onBirthdaySelected(d: Date) {
    //     console.log(d);
    // }

    useEffect(() => {
        setName(props.name);
        setPhoneNumber(props.phoneNumber);
        setBirthDay(props.birthDay);
    }, [ props.birthDay, props.name, props.phoneNumber ]);

    useEffect(() => {
        setDisabled(!name || !phoneNumber || !birthDay);
    }, [ name, phoneNumber, birthDay ]);

    function onNext() {
        const service = new KakaoTalkService();
        // service.sendMessage('010-3380-0842', 'adad', 'a').then(() => {});
        props.onEntered(name!, phoneNumber!, birthDay!);
    }

    return (
        <div className={'privacy-form'}>
            <div className={'privacy-form-content'}>
                <div className={'privacy-form-content-title'}>One on one skin consultation</div>
                <div className={'privacy-form-content-description'}>
                    <div className={'privacy-form-content-description-text'}>
                        <p>피부 문제의 근본적 개선을 위해 스킨 컨설턴트와의 원온원 스킨 컨설테이션을 경험해보세요.</p>
                        <p>컨설테이션 후에는 시르즈의 가장 효과적인 제품 5종이 담긴 신시어 키트를 전해드립니다.</p>
                    </div>

                    <div className={'privacy-form-content-description-additional'}>
                        <p>* 진단에 앞서 고객님의 핸드폰으로 진단 결과를 공유하고 지속적인 애프터 케어를 제공하기 위해 이름, 전화번호, 생년월일 작성을 부탁드립니다.</p>
                        <p>이외의 용도로는 사용되지 않습니다.</p>
                    </div>
                </div>

                <div className={'privacy-form-content-description-form'}>
                    <TextInputField label={'이름'}
                                    value={props.name}
                                    onEntered={(text: string) => setName(text)}/>

                    <TextInputField label={'전화번호'}
                                    type={'tel'}
                                    value={props.phoneNumber}
                                    onEntered={(text: string) => setPhoneNumber(text)}
                                    hint={'* 숫자만 입력해주세요'}/>

                    <BirthDaySelector label={'생년월일'}
                                      value={props.birthDay ?? undefined}
                                      onSelected={(birthDay) => setBirthDay(birthDay)}/>
                </div>
            </div>
            <div className={'privacy-form-action-bar'}>
                <Button onClick={onNext} disabled={disabled}>설문시작</Button>
            </div>
        </div>
    );
}

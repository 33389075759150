import { ReactElement, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle } from '@mui/material';

import './survey-agreement-dialog.scss'
import ConfigurationService from '../../services/configuration-service';
import ConsultingCheckBox from '../consulting-check-box/consulting-check-box';

interface SurveyAgreementDialogProps {
    opened: boolean;
    onAgreed: () => void;
    onCanceled: () => void;
}

export default function SurveyAgreementDialog(props: SurveyAgreementDialogProps): ReactElement {
    const [ text, setText ] = useState<string>('');

    function onAgreed(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            const interval = setInterval(() => {
                clearInterval(interval);
                props.onAgreed();
            }, 400);
        }
    }

    useEffect(() => {
        async function execute() {
            const service = new ConfigurationService();
            setText(await service.getPrivacyAgreementText());
        }

        execute();
    }, [ props ]);

    return (
        <div className={'survey-agreement-dialog'}>
            <Dialog open={props.opened}
                    onClose={() => props.onCanceled()}
            >
                <DialogTitle>SYRS 개인 정보 수집 및 이용 동의</DialogTitle>
                <DialogContent>
                    <div className={'notice-text'}>
                        <p>One on one skin consultation 서비스는 피부 상태에 대한 설문 조사 후</p>
                        <p>컨설팅 결과를 고객님의 핸드폰으로 제공해드립니다.</p>
                        <p>서비스 이용을 위한 개인정보 수집 및 이용에 동의하십니까?</p>
                    </div>

                    <div className={'privacy-policy-text'} dangerouslySetInnerHTML={{ __html: text }}>

                    </div>
                    <div className={'agreement-text'}>
                        <ConsultingCheckBox title={'위 내용을 모두 확인하였고 동의 합니다.'} onChange={onAgreed}/>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
